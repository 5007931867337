
document.addEventListener('DOMContentLoaded', () => {
    var headerPath = '/header.html';
    var footerPath = '/footer.html';

    // Function to fetch and add header content
    function addHeader() {
        fetch(headerPath)
            .then(response => response.text())
            .then(html => {
                document.getElementById('header').innerHTML = html;
            })
            .catch(error => console.error('Error loading header:', error));
    }

    // Function to fetch and add footer content
    function addFooter() {
        fetch(footerPath)
            .then(footerResp => footerResp.text())
            .then(footerHtml => {
                document.getElementById('footer').innerHTML = footerHtml;
            })
            .catch(error => console.error('Error loading footer:', error));
    }

    addHeader();
    addFooter(); 
});
